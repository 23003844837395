import React from "react";
import { Link, graphql } from "gatsby";
import StringSimilarity from "string-similarity";
import Layout from "../components2/Layout";
import Seo from "../components2/Seo";
import styled from "styled-components";

const Background = styled.div`
  min-height: calc(100vh - 90px);
  display: flex;
  flex-irection: column;
  justify-content: center;

  @media screen and (max-width: 1920px) {
  }
`;
const FirstWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Container = styled.div`
  max-width: 800px !important;
  padding-top: 100px !important;
  padding-bottom: 100px !important;

  @media only screen and (min-width: 48em) {
    margin: auto;
    max-width: 46rem;
  }
  @media only screen and (min-width: 62em) {
    margin: auto;
    max-width: 61rem;
  }

  display: -moz-flex;
  display: flex;
  -moz-flex: 0 1 auto;
  flex: 0 1 auto;
  -moz-flex-direction: row;
  flex-direction: row;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  box-sizing: border-box;
`;

const Title = styled.h1`
  display: block;
  font-size: 3em;
  font-weight: 700;
  margin: 0.67em 0;
  margin-bottom: 15px !important;
  margin-top: 15px !important;
`;

const PrevLink = styled(Link)`
  -webkit-flex-shrink: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  display: inline-block;
  border-bottom: 2px solid transparent;
  font-family: "Epilogue", "Arial", sans-serif;
  font-weight: 700;
  color: #7e913b;
  text-transform: uppercase;
  -webkit-letter-spacing: 0.1em;
  -moz-letter-spacing: 0.1em;
  -ms-letter-spacing: 0.1em;
  letter-spacing: 0.1em;
  grid-area: Left;
  text-align: center;
  font-size: 1.25rem;
`;

const FourOhFour = ({ location, data }) => {
  const pages = data.allSitePage.nodes.map(({ path }) => path);
  const pathname = location.pathname;
  const result = StringSimilarity.findBestMatch(pathname, pages).bestMatch;
  function renderContent() {
    return result.rating > 0.35 ? (
      <>
        <Title>
          You were probably looking for{" "}
          <Link to={result.target} className="is-special-blue">
            {result.target}
          </Link>
        </Title>
        <h3 className="is-grey margin-3-b margin-5-t">
          Not what you're after?
          <br />
          <br />
          Click your heels together three times and say 'There's no place like
          home', press the button below, and you'll be there.
        </h3>
      </>
    ) : (
      <>
        <Title className="is-hero-menu margin-3-t is-grey margin-3-b">
          Yep, you're lost.
        </Title>
        <h3 className=" is-grey margin-5-b">
          Click your heels together three times and say 'There's no place like
          home', press the button below, and you'll be there.
        </h3>
      </>
    );
  }

  return (
    <Layout>
      <Seo
        title={`多種多肉的404頁`}
        description={`這一頁是為了被迷路的多種多肉客人`}
      />
      <Background>
        <FirstWrapper>
          <Container>
            <h3 className="is-grey margin-1-tb">PAGE NOT FOUND 😭</h3>
            {renderContent()}
            <Link
              to={"/"}
              style={{ textDecoration: "none" }}
              className=" align-horizontal is-white lato margin-4-r"
            >
              <PrevLink to={`/`} rel="home">
                ⬅ There's no place like home
              </PrevLink>
            </Link>
          </Container>
        </FirstWrapper>
      </Background>
    </Layout>
  );
};

export const pageQuery = graphql`
  {
    allSitePage(
      filter: { path: { nin: ["/dev-404-page", "/404", "/404.html"] } }
    ) {
      nodes {
        path
      }
    }
  }
`;
export default FourOhFour;
